<template>
  <div class="confirm-order">
    <div class="go-back">
      <i class="el-icon-arrow-left" @click="goBack"></i>
      <span @click="goBack">返回上一页</span>
    </div>
    <div class="class-title">
      <span>{{ groupObject.topic || '暂无名称' }}</span>
      <span>({{ groupObject.courseCount }}门课, 每门课程可分配给{{ groupObject.comAllotNum }}人学习)</span>
    </div>
    <div class="class-info">
      <div class="class-info-img">
        <img :src="groupObject.coverPicture" alt="" class="order-img" v-if="groupObject.coverPicture" />
        <img src="../../assets/placeholder.png" alt="" class="order-img" v-else />
      </div>
      <div class="class-info-right">
        <div class="class-info-item">
          <div class="item-label">单价</div>
          <div class="item-content">
            <div class="item-price" :class="payType ? 'act-item-price' : ''" @click="subPayPrice">
              <img src="../../assets/jindou.png" alt="" />
              <span>{{ groupObject.comPayPrice }}</span>
              <span
                class="unit"
                :style="
                  (
                    payType
                      ? showTotalMoney
                        ? showTotalMoney <= companybalance
                        : totalMoney <= companybalance
                      : selectVoucherList.length > 0 && selectVoucherList.length == needNum
                  )
                    ? ''
                    : 'color:#F0314A;'
                "
              >
                (账户余额：{{ companybalance }})
              </span>
            </div>
            <div class="voucher-box">
              <div
                class="item-voucher"
                :class="item.voucherId == needVoucherItem.voucherId ? 'act-item-voucher' : ''"
                v-for="(item, index) in groupObject.groupVouchers"
                :key="index"
                @click="getVoucherItemNum(item)"
              >
                <img :src="item.voucherIcon" alt="" />
                <span>{{ item.voucherCount }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="class-info-item" style="margin-top: 20px">
          <div class="item-label">数量</div>
          <div class="item-ipt">
            <el-input-number
              v-model="num"
              :min="1"
              :max="999"
              :precision="0"
              :disabled="isJupm"
              size="small"
              @blur="handleIptNum(num)"
            ></el-input-number>
          </div>
          <div class="item-select" v-show="screenUserList.length > 1">
            <div>
              企业云屏
              <span style="color: #d9d9d9; padding: 0 15px">|</span>
            </div>
            <div class="pay-item-select">
              <el-select v-model="screenUserId" placeholder="请选择大屏" :disabled="Boolean($route.query.isJupm)">
                <el-option v-for="item in screenUserList" :key="item.userId" :label="item.nickName" :value="item.userId"></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 云豆支付展示的 -->
    <div class="pay-money" v-if="payType">
      <div class="pay-money-item">
        <span>商品总额：</span>
        <span>{{ totalMoney }}</span>
      </div>
      <div class="pay-money-item">
        <span>商品优惠：</span>
        <span
          style="color: #4a9dff; font-size: 14px"
          :style="isJupm ? '' : 'cursor: pointer;'"
          @click="openDiscountDialog"
          v-show="isJupm ? selectShowList.length > 0 : isShowDis"
        >
          {{ selectShowList.length > 0 ? `已选${selectShowList.length}个优惠` : `点击选择优惠` }}
          <i class="el-icon-arrow-right" v-if="!isJupm"></i>
        </span>
        <span style="color: #4a9dff; font-size: 14px" v-show="isJupm ? selectShowList.length == 0 : !isShowDis">暂无优惠</span>
      </div>
      <div class="pay-money-dis">
        <div class="dis-item" v-for="(item, index) in selectShowList" :key="index">
          <div>
            <span>{{ item.discountName }}</span>
            <span>（{{ item.discountNum / 10 }}折）</span>
          </div>
          <span>- {{ item.newPrice - Math.ceil(item.newPrice * (item.discountNum / 100)) }}</span>
        </div>
      </div>
    </div>
    <!-- 兑换券支付展示的 -->
    <div style="margin: 20px 0" v-show="!payType">
      <voucher-show
        :payType="payType"
        :groupId="groupId"
        :num="num"
        :needVoucherItem="needVoucherItem"
        :groupVouchers="groupVouchers"
        @handleClickVoucherItem="handleClickVoucherItem"
      ></voucher-show>
    </div>

    <div class="pay-bottom">
      <div class="pay-box">
        <div class="pay-box-box">
          <div class="pay-box-money">
            <div class="pay-num">共{{ num }}件</div>
            <div class="pay-actually">
              <span>实付：</span>
              <img src="../../assets/jindou.png" alt="" />
              <span style="font-size: 30px; color: #f0314a">{{ !payType ? 0 : showTotalMoney || totalMoney }}</span>
            </div>
          </div>
          <div
            class="pay-box-nomoney"
            v-if="
              (
                payType
                  ? showTotalMoney
                    ? showTotalMoney <= companybalance
                    : totalMoney <= companybalance
                  : selectVoucherList.length > 0 && selectVoucherList.length == needNum
              )
                ? false
                : true && payType
            "
          >
            余额不足
            <span @click="goIndex">（请充值）</span>
          </div>
        </div>
        <div class="pay-btn-loading" v-show="isLoading" v-loading="isLoading" element-loading-text="正在下单..."></div>
        <div
          class="pay-btn-right"
          v-if="permissions && !isLoading"
          :style="
            (
              payType
                ? showTotalMoney
                  ? showTotalMoney <= companybalance
                  : totalMoney <= companybalance
                : selectVoucherList.length > 0 && selectVoucherList.length == needNum
            )
              ? 'cursor:pointer; background:#F0314A;'
              : ''
          "
          @click="openPayDialog"
        >
          立即下单
        </div>
      </div>
    </div>

    <password-dialog
      :show.sync="showPasswordDialog"
      :orderNo="orderNo"
      :orderId="orderId"
      :accountNum="accountNum"
      :selectVoucherList="selectVoucherList"
    ></password-dialog>
    <!-- 优惠券弹框 -->
    <discount-dialog
      :show.sync="showDiscountDialog"
      :groupId="groupObject.id"
      :selectShowList="selectShowList"
      :totalMoney="totalMoney"
      @submitDiscount="submitDiscount"
      @getExistDis="getExistDis"
    ></discount-dialog>
  </div>
</template>

<script>
import VoucherShow from './components/voucherShow.vue'
import PasswordDialog from './components/passwordDialog'
import DiscountDialog from './components/discountDialog'
import { getComAccount, toplaceV2, getScrUserList, getOrderDetail } from '@/api/confirmOrder.js'
import { getGroupInfo } from '@/api/courseGroupDetail.js'
import { getInfo } from '@/api/login'
import { mapActions, mapGetters } from 'vuex'
import { setUserInfo, setPermissions } from '@/utils/user'
import { funCalc } from '@/utils/common.js'
import { getBackstageUrl } from '@/utils/config.js'
import { getDiscountPrice } from '@/utils/common.js'
import { getToken } from '@/utils/auth'
export default {
  name: 'ConfirmOrder',
  components: {
    VoucherShow,
    PasswordDialog,
    DiscountDialog
  },
  computed: {
    totalMoney() {
      // 解决精度丢失
      return funCalc([this.groupObject.comPayPrice, this.num], 2, 0)
    },
    ...mapGetters(['permissions'])
  },
  data() {
    return {
      isLoading: false, // 订单提交loading
      groupId: null, // 课程组ID
      groupObject: {}, // 课程组详情
      num: 1, // 课程组数量
      selectVoucherList: [], // 选择的优惠券列表
      companybalance: null, // 企业账户余额
      payType: true, // true 余额支付 false兑换券支付
      zhekou: null, // 折扣券
      zhekouList: [], // 折扣券列表
      screenUserId: null,
      screenUserList: [], // 大屏列表
      agreementType: true, // 同意协议  目前没有默认同意
      showPasswordDialog: false,
      orderNo: null,
      orderId: null,
      groupVouchers: [], // 后端返回的兑换券集合
      needVoucherItem: {}, // 需要的兑换券类型和数量
      needNum: 0, // 需要的数量
      isJupm: false, // 是否是从后台跳转来的订单
      accountNum: '',
      showDiscountDialog: false, // 选择折扣弹框
      selectShowList: [], // 展示选择的折扣列表
      showTotalMoney: 0,
      paymentType: null, // 支付方式（1:微信 0:支付宝,2:苹果支付,3兑换券,4代币）
      isShowDis: false // 是否有显示优惠
      // voucherId: null // 选择的兑换券类型id
    }
  },
  created() {
    console.log(("订单---"))
    if (this.$route.query.id) {
      this.groupId = this.$route.query.id
      if (!this.$route.query.isJupm) {
        this.getGroupInfo(this.$route.query.id)
      }
    }
    if (this.$route.query.isJupm) {
      this.isJupm = true
      this.num = Number(this.$route.query.orderItemQuantity)
      this.orderNo = this.$route.query.orderNo
      // this.getInfo()
      this.getOrderDetail(this.orderNo)
    } else {
      this.isJupm = false
    }
    //获取账号的余额
    this.getComAccount()
    // 获取大屏数据
    this.getScrUserList()
  },
  methods: {
    ...mapActions(['SET_USERINFO', 'SET_PERMISSIONS']),
    // 返回上一页
    goBack() {
      this.$router.push({
        path: '/courseGroupDetail',
        query: { id: this.groupId }
      })
    },
    handleIptNum(val) {
      if (val == undefined) {
        this.num = 1
      }
    },
    // 课程组详情--无订单时
    async getGroupInfo() {
      let res = await getGroupInfo(this.groupId)
      this.groupObject = res.data || {}
      this.groupVouchers = res.data?.groupVouchers || [] // 后端返回的兑换券集合
    },
    // 有订单时获取订单详情
    async getOrderDetail() {
      let res = await getOrderDetail(this.orderNo)
      this.groupObject = res.data.trCourseGroup || {}
      // 下完单的页面要把兑换券类型锁死
      this.groupVouchers = res.data?.trCourseGroup.groupVouchers || [] // 后端返回的兑换券集合
      this.groupObject.groupVouchers = this.groupVouchers.filter(item => item.voucherId == res.data?.trCourseGroup.voucherId)
      this.payType = res.data.paymentType == 3 ? false : true
      this.screenUserId = res.data.scrUserId
      // this.voucherId = res.data?.trCourseGroup.voucherId
      // 拿到上次下单时选的兑换券类型
      this.needVoucherItem = this.groupObject.groupVouchers[0]
      // 上次下单选择的权益券列表 不可修改
      let discountList = res.data?.discountList || []
      // appendFlag 是否允许叠加，0：否，1：是
      if (discountList.some(item => item.appendFlag == 1)) {
        // 是可叠加兑换券
        this.selectShowList = getDiscountPrice(discountList, [], res.data.orderItemId, Number(this.totalMoney)).showList
      } else {
        this.selectShowList = getDiscountPrice([], discountList, res.data.orderItemId, Number(this.totalMoney)).showList
      }
      this.submitDiscount(this.selectShowList)
    },
    // 获取企业账户余额
    async getComAccount() {
      try {
        let res = await getComAccount()
        this.companybalance = res.data?.balanceShow || 0 // 余额
        this.accountNum = res.data?.phone || '' // 负责人手机号
      } catch (error) {}
    },
    // 获取企业内大屏数据
    async getScrUserList() {
      try {
        let res = await getScrUserList()
        this.screenUserList = res.data || []
        if (!this.$route.query.isJupm) {
          this.screenUserId = this.screenUserList.length > 0 ? this.screenUserList[0].userId || null : null
        }
      } catch (error) {}
    },
    // 点击获取此类型的兑换券
    getVoucherItemNum(item) {
      this.needVoucherItem = item
      this.payType = false
    },
    // 选择的兑换券列表
    handleClickVoucherItem(list, num) {
      this.selectVoucherList = list
      this.needNum = num
    },
    // 使用余额支付
    subPayPrice() {
      // this.paymentType == 4 &&
      if (!this.$route.query.isJupm) {
        // 如果是从后台支付跳转进来的，并且支付方式是兑换券,则不能点击
        this.payType = true
        this.needVoucherItem = {}
      }
    },
    // 打开选择折扣弹框
    openDiscountDialog() {
      if (!this.$route.query.isJupm) {
        //  从后台支付进入不让打开弹框
        this.showDiscountDialog = true
      }
    },
    // 选择的折扣列表
    submitDiscount(list) {
      this.selectShowList = list
      this.showTotalMoney = this.selectShowList.reduce((reduce, item) => {
        // 可叠加的折扣计算出来的最后金额 优惠是向下取整 比如优惠是2.8个豆 则优惠2个豆 5696bug
        // 原价999 打7.7折，用户应该付999*0.77=769.23 实际应该付770 向上取整
        const discount = item.discountNum / 100
        this.$set(item, 'newPrice', Number(reduce))
        return Math.ceil(reduce * discount)
      }, this.totalMoney)
    },
    // 获取是否有可选的商品优惠
    getExistDis(appendItemList, appendNoItemList) {
      this.isShowDis = appendItemList.length > 0 || appendNoItemList.length > 0
    },
    // 立即下单按钮
    openPayDialog() {
      if (
        this.payType
          ? this.showTotalMoney
            ? this.showTotalMoney <= this.companybalance
            : this.totalMoney <= this.companybalance
          : this.selectVoucherList.length > 0 && this.selectVoucherList.length == this.needNum
      ) {
        if (this.agreementType) {
          if (this.isJupm) {
            this.showPasswordDialog = true
          } else {
            this.toplaceV2()
          }
        } else {
          this.$message({
            message: '请先同意《企云巅服务协议》！',
            type: 'warning'
          })
        }
        // this.showPasswordDialog = true
      }
    },
    // 下单接口
    async toplaceV2() {
      if (this.selectVoucherList.length > 0) {
        // 此时使用的是兑换券
        this.paymentType = 3
      } else {
        this.paymentType = 4
      }
      this.isLoading = true
      let params = {
        orderItemType: 3, // 商品类别,0课程，1套餐，2资源包，3课程组，4代币
        orderItemId: this.groupId, // 商品ID
        orderItemQuantity: this.num, // 商品数量
        orderType: 1, // 购买方式 0：个人 1:企业
        buyerType: 1, //移动:0  web:1
        scrUserId: this.screenUserId, // 关联大屏用户ID
        discountList: this.selectShowList || [], // 折扣列表
        voucherId: this.needVoucherItem.voucherId, // 选择的兑换券列表id
        voucherPayNum: this.selectVoucherList.length, //兑换券支付数量
        paymentType: this.paymentType //1:微信 0:支付宝,2:苹果支付,3兑换券,4代币
      }
      try {
        let res = await toplaceV2(params)
        this.orderNo = res.data.orderNo
        this.orderId = res.data.id
        this.showPasswordDialog = true
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        if (error.code == 401) {
          this.$confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '提示', {
            confirmButtonText: '重新登录',
            showClose: false,
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            type: 'warning'
          })
            .then(() => {
              this.$router.push('/loginStore')
            })
            .catch(() => {
              this.$router.go(0)
              console.log('取消了')
            })
        } else {
          this.$message.error(error.msg || '下单失败!')
        }
      }
    },
    // 获取用户详情
    getInfo() {
      getInfo()
        .then(res => {
          let user = res.data.user
          let permissionFlag = ['shopping:record:pay', 'order:list:pay', 'shopping:businessVoucher:list']
          let permissions = res.data.permissions.some(permission => {
            return '*:*:*' === permission || permissionFlag.includes(permission)
          })
          let { userName, companyName, userId, companyId } = res.data.user
          let avatar = user.avatar ?? require('@/assets/no-avatar.png')
          let userInfo = {
            name: userName,
            avatar: avatar,
            companyName,
            userId,
            companyId
          }
          this.SET_USERINFO(userInfo)
          this.SET_PERMISSIONS(permissions)
          setUserInfo(JSON.stringify(userInfo))
          setPermissions(JSON.stringify(permissions))
        })
        .catch(err => {})
    },
    goIndex() {
      this.$confirm('即将跳转到后台管理系统，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          window.open(getBackstageUrl() + `/order/accountManage?token=${getToken()}&isStore=true`, '_blank')
          // window.open(getBackstageUrl() + `/order/accountManage?isStore=true`, '_blank')
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.confirm-order {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 60px;
  .go-back {
    display: flex;
    align-items: center;
    padding: 20px 0;
    i {
      color: #9e9e9e;
      cursor: pointer;
    }
    span {
      display: inline-block;
      font-size: 16px;
      color: #999999;
      margin-left: 11px;
      cursor: pointer;
    }
  }
  .class-title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    color: #333333;
    span:nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      padding-left: 16px;
    }
  }
  .class-info {
    display: flex;
    justify-content: space-between;
    .class-info-img {
      width: 520px;
      height: 307px;
      border-radius: 10px 10px 10px 10px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .class-info-right {
      width: 660px;
      .class-info-item {
        .item-label {
          font-weight: bold;
          font-size: 14px;
          color: #333333;
          margin-bottom: 12px;
        }
        .item-content {
          height: 143px;
          background: rgba(246, 246, 246, 0.8);
          padding: 10px;
          display: flex;
          .item-price {
            width: 160px;
            height: 37px;
            display: flex;
            align-items: center;
            border: 1px solid #e5e5e5;
            padding-left: 8px;
            cursor: pointer;
            position: relative;
            margin-right: 20px;
            img {
              width: 22px;
              height: 20px;
              margin-right: 6px;
            }
            span {
              font-size: 20px;
              color: #333333;
              font-weight: bold;
            }
            .unit {
              position: absolute;
              font-weight: 400;
              font-size: 12px;
              color: #666666;
              left: 0;
              bottom: -24px;
            }
          }
          .act-item-price {
            border: 1px solid #369eff;
          }
          .voucher-box {
            width: calc(100% - 160px);
            display: flex;
            flex-wrap: wrap;
            max-height: 123px;
            overflow-y: auto;
            &::-webkit-scrollbar {
              /*滚动条整体样式*/
              width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
              height: 1px;
            }
            &::-webkit-scrollbar-thumb {
              /*滚动条里面小方块*/
              border-radius: 3px;
              background-color: #d9d9d9;
            }
            &::-webkit-scrollbar-track {
              /*滚动条里面轨道*/
              box-shadow: inset 0 0 5px #fff;
              background: #ededed;
              border-radius: 10px;
            }
            .item-voucher {
              display: flex;
              align-items: center;
              width: 71px;
              height: 37px;
              margin-right: 20px;
              border: 1px solid #e5e5e5;
              padding-left: 6px;
              margin-bottom: 20px;
              cursor: pointer;
              img {
                width: 29px;
                height: 25px;
                margin-right: 6px;
              }
              span {
                font-size: 18px;
                color: #333333;
                font-weight: bold;
              }
            }
            .act-item-voucher {
              border: 1px solid #369eff;
            }
          }
        }
        .item-select {
          margin-top: 6px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #333333;
          .pay-item-select ::v-deep {
            .el-select {
              width: 285px;
            }
            .el-input__inner {
              border: none;
              color: #369eff;
            }
          }
        }
      }
    }
  }
  .pay-money {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .pay-money-item {
      width: 400px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      span {
        display: inline-block;
        &:first-child {
          width: 220px;
          font-size: 16px;
          color: #666666;
        }
        &:last-child {
          font-size: 20px;
          color: #333333;
        }
      }
    }
    .pay-money-dis {
      width: 400px;
      .dis-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        span {
          display: inline-block;
          font-size: 20px;
          color: #f0314a;
        }
        div {
          display: flex;
          span {
            display: inline-block;
            font-size: 16px;
            color: #666666;
          }
          span:nth-child(1) {
            max-width: 210px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .pay-bottom {
    float: right;
    margin-top: 40px;
    margin-bottom: 100px;
    .pay-box {
      display: flex;
      align-items: flex-end;
      align-items: center;
      .pay-box-box {
        display: flex;
        flex-direction: column;
        .pay-box-money {
          display: flex;
          align-items: center;
        }
        .pay-box-nomoney {
          cursor: pointer;
          font-size: 14px;
          color: #f0314a;
          span {
            color: #4a9dff;
          }
        }
      }
      .pay-num {
        font-weight: bold;
        color: #333333;
        margin-right: 18px;
      }
      .pay-actually {
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-right: 40px;

        img {
          width: 22px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .pay-btn-loading {
        width: 100px;
        ::v-deep .el-loading-spinner {
          display: flex;
          flex-direction: column;
          align-items: center;
          .circular {
            height: 30px;
          }
        }
      }
      .pay-btn-right {
        width: 260px;
        height: 50px;
        color: #fff;
        line-height: 50px;
        text-align: center;
        background: #acacac;
        border-radius: 4px;
        cursor: no-drop;
      }
    }
  }
}
</style>
