import request from '@/utils/request'
// 获取首页banner
export function homeBanner(params) {
  return request({
    url: `train/recommend/homeBanner`,
    method: 'GET',
    params
  })
}
// 获取首页其他数据
export function recommendList(params) {
  return request({
    url: `train/recommend/web/recommendList`,
    method: 'GET',
    params
  })
}
// 查其他分类的列表
export function webCourseGroupList(params) {
  return request({
    url: `train/group/webCourseGroupList`,
    method: 'GET',
    params
  })
}
//
export function getGroupInfo(id) {
  return request({
    url: `train/group/webGroupInfo/${id}`,
    method: 'GET'
  })
}

// 获取商品优惠和权益信息
export function getSelectDiscounts() {
  return request({
    url: '/shopping/selectDiscounts',
    method: 'get'
  })
}
