const getter = {
  equipmentType: state => state.base.equipmentType,
  token: state => state.user.token,
  storeToken: state => state.user.storeToken,
  permissions: state => state.user.permissions,
  userInfo: state => state.user.userInfo,
  userType: state => state.user.userType,
  payInformation: state => state.payInformation.payInformation,
  visibleFloatButton: state => state.floatButton.visibleFloatButton,
  visibleStartPopup: (state) => state.startPopup.visibleStartPopup,
}

export default getter
