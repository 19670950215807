const floatButton = {
  state: {
    visibleFloatButton: true, // 是否显示悬浮按钮
  },

  mutations: {
    set_visibleFloatButton(state, visibleFloatButton) {
      state.visibleFloatButton = visibleFloatButton
    },
  },
  actions: {
    SET_VISIBLEFloatBUTTON(content, payload) {
      content.commit('set_visibleFloatButton', payload)
    },
  },
}

export default floatButton
