import request from '@/utils/request'

// 终端获取活动配置
export function getPlatformSettingList(query) {
    return request({
        url: '/systemSetting/activity/getPlatformSettingList',

        method: 'get',
        params: query
    })
}

// 终端获取跳转详情校验
export function checkRedirect(query) {
    return request({
        url: '/systemSetting/activity/checkRedirect',
        method: 'get',
        params: query
    })
}
// 获取平台侧信息
export function getPlantformDetail() {
    return request({
        url: `systemSetting/platform/selectDefaultPlatform`,
        method: 'GET'
    })
}