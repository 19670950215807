import Vue from 'vue'
import Vuex from 'vuex'
import base from './modules/base'
import user from './modules/user'
import payInformation from './modules/payInformation'
import floatButton from './modules/floatButton'
import startPopup from './modules/startPopup'
import getters from './getter'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    base,
    user,
    payInformation,
    floatButton,
    startPopup
  },
  getters
})
