<template>
  <div class="float-box">
    <div
      class="button-box"
      :style="{ backgroundImage: `url(${backgroundUrl})`, height: `${currentHeight}` }"
      @mousedown="mousedown"
      @mouseup="mouseup"
      @touchstart="mousedown"
      @touchend="mouseup"
      @click="onClick"
      ref="floatButtonRef"
    >
      <img class="close-icon" src="../../assets/delete_fill.png" @click.stop="onCloseFloatButton" v-if="visibleCloseIcon" />
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FloatButton',
  props: {
    right: {
      type: String,
      default: '30px'
    },
    bottom: {
      type: String,
      default: '100px'
    },
    backgroundUrl: {
      type: String,
      default: require('@/assets/button-bac.webp')
    },
    visibleCloseIcon: {
      type: Boolean,
      default: true
    },
    floatData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      left: 0,
      top: 0,
      mouseDownflag: false, // 鼠标点击状态
      position: {
        x: 0,
        y: 0
      }, // 初始位置
      nx: '', // 偏移量
      ny: '',
      startX: '',
      startY: '',
      newElemenyX: '', // 最新坐标
      newElementY: '',
      movb: 1 //区分拖拽还是点击
    }
  },
  mounted() {
    this.setStartPosition()
  },
  computed: {
    currentHeight() {
      let img = new Image()
      img.src = this.backgroundUrl
      return (100 / img.width) * img.height + 'px'
      // return img.width ? (100 / img.width) * img.height + 'px' : '110px'
    }
  },
  methods: {
    //鼠标摁下
    mousedown(event) {
      this.mouseDownflag = true
      /*此处判断pc 或移动端得到event 事件*/
      const touch = event.touches ? event.touches[0] : event // 获取touch对象
      //鼠标点击面向页面的x坐标y坐标 相对于浏览器的x坐标y坐标
      this.position.x = touch.clientX
      this.position.y = touch.clientY
      this.startX = this.$refs.floatButtonRef.offsetLeft // 记录按钮的初始x坐标 相对于视口的左侧偏移量
      this.startY = this.$refs.floatButtonRef.offsetTop // 记录按钮的初始y坐标
      document.onmousemove = event => {
        if (this.mouseDownflag) {
          this.movb = 2
          /*得到event事件*/
          const touch = event.touches ? event.touches[0] : event // 获取touch对象
          //鼠标坐标－鼠标与拖拽按钮的间距坐标得到拖拽按钮的左上角x轴y轴坐标
          this.nx = touch.clientX - this.position.x // 计算鼠标移动的x方向偏移量
          this.ny = touch.clientY - this.position.y // 计算鼠标移动的y方向偏移量
          this.newElemenyX = this.startX + this.nx // 计算按钮的新x坐标
          this.newElementY = this.startY + this.ny // 计算按钮的新y坐标
          let width = window.innerWidth - this.$refs.floatButtonRef.offsetWidth //屏幕宽度减去自身控件宽度  计算屏幕可用宽度
          let height = window.innerHeight - this.$refs.floatButtonRef.offsetHeight //屏幕高度减去自身控件高度 计算屏幕可用高度
          /*
          拖拽按钮 如果超出屏幕宽高或者小于
          设置屏幕最大 x=全局容器x y=全局容器y
          否则 设置 为 x=0 y=0
        */
          this.newElemenyX < 0 && (this.newElemenyX = 0)
          this.newElementY < 0 && (this.newElementY = 0)
          this.newElemenyX > width && (this.newElemenyX = width)
          this.newElementY > height && (this.newElementY = height)
          //  计算后坐标  设置 按钮位置
          this.$refs.floatButtonRef.style.left = this.newElemenyX + 'px'
          this.$refs.floatButtonRef.style.top = this.newElementY + 'px'
          this.left = this.newElemenyX + 20
          this.top = this.newElementY
          // 阻止页面的滑动默认事件
          document.addEventListener(
            'touchmove',
            function () {
              event.preventDefault()
            },
            false
          )
        }
      }
    },
    //鼠标拖拽移动
    mousemove(event) {},
    //鼠标抬起
    mouseup() {
      this.mouseDownflag = false
    },
    setStartPosition() {
      this.$refs.floatButtonRef.style.right = this.right
      this.$refs.floatButtonRef.style.bottom = this.bottom
    },
    onClick() {
      if (this.movb === 2) {
        this.movb = 1
      } else {
        this.$emit('onClickFloatButton', { floatData: this.floatData })
      }
    },
    onCloseFloatButton() {
      this.$emit('onCloseFloatButton')
    }
  }
}
</script>
<style scoped lang="scss">
.float-box {
  .button-box {
    width: 110px;
    // height: 110px;
    // border-radius: 50%;
    position: fixed;
    right: 30px;
    bottom: 100px;
    touch-action: none;
    text-align: center;
    color: white;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 100px;
    font-size: 14px;
    cursor: pointer;
    z-index: 9999;
    .close-icon {
      border-radius: 50%;
      position: absolute;
      right: -15px;
      top: -30px;
      width: 30px;
      height: 30px;
    }
  }
}
</style>
