<template>
  <div class="start-dialog" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="container-image">
      <el-image fit="fill" :src="imgSrc" @click="doJump"></el-image>
      <img @click="doCancel" class="close-icon" src="~@/assets/img-close.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartPopup',
  props: ['imgSrc'],
  data() {
    return {}
  },
  created() {
    console.log('启动弹窗', this.imgSrc)
  },
  methods: {
    doJump() {
      this.$emit('doJump')
    },
    doCancel() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" >
.start-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.container-image {
  cursor: pointer;
  position: relative;
  width: 1066px;
  height: 600px;
  border-radius: 20px;
  .el-image{
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
  .close-icon {
    position: absolute;
    top: -55px;
    right: 0;
    width: 42px;
    height: 42px;

    cursor: pointer;
    font-size: 50px;
  }
}
@media screen and (min-width: 1921px) {
  .container-image {
    width: 1600px;
    height: 900px;
  }
}
@media screen and (min-width: 1281x) and (max-width: 1920px) {
  .container-image {
    width: 1066px;
    height: 600px;
  }
}
@media screen and (max-width: 1280px) {
  .container-image {
    width: 710px;
    height: 396px;
  }
}
</style>