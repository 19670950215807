// import Cookies from 'js-cookie'

const VisibleFloatButtonList = 'visibleFloatButtonList'

export function getVisibleFloatButtonList() {
  return JSON.parse(sessionStorage.getItem(VisibleFloatButtonList))
}

export function setVisibleFloatButtonList(data) {
  return sessionStorage.setItem(VisibleFloatButtonList, JSON.stringify(data))
}

export function removeVisibleFloatButtonList() {
  return sessionStorage.removeItem(VisibleFloatButtonList)
}
