import request from '@/utils/request'
// 获取全部课程
export function getAppList(params) {
  return request({
    url: `train/course/appList`,
    method: 'GET',
    params
    // headers: {
    //   isToken: false // 不加token
    // }
  })
}

// 获取全部课程分类
export function getListByParent() {
  return request({
    // url: `train/courseClassify/listByParent`,
    url: `systemSetting/platform/listByParent`,
    method: 'GET',
    headers: {
      // isToken: false
    }
  })
}
