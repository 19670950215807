const startPopup = {
    state: {
        visibleStartPopup: true, // 是否显示悬浮按钮
    },

    mutations: {
        set_visibleStartPopup(state, visibleStartPopup) {
            state.visibleStartPopup = visibleStartPopup
        },
    },
    actions: {
        SET_VISIBLESTARTPOPUP(content, payload) {

        },
    },
}

export default startPopup
