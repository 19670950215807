<template>
  <div class="home" v-loading="loading">
    <div class="home-banner">
      <banner-box></banner-box>
    </div>
    <div class="home-content">
      <course-list></course-list>
    </div>
    <!-- v-if="visibleFloatButton && token" -->
    <!-- 用户登录之后才可以看到悬浮按钮只能在首页看到 -->
    <float-button
      v-if="visibleFloatButton && token && !loading"
      @onClickFloatButton="onClickFloatButton"
      @onCloseFloatButton="onCloseFloatButton"
      :backgroundUrl="backgroundUrl"
      :floatData="floatData"
    ></float-button>

    <start-popup v-if="visibleStartPopup && token && !loading" @close="closeStartPopup" @doJump="handlePopupJump" :imgSrc="popupSrc"></start-popup>
  </div>
</template>

<script>
import CourseList from './components/courseList.vue'
import BannerBox from './components/bannerBox.vue'
import { mapActions, mapGetters } from 'vuex'
import { getToken, setStoreToken, removeToken, removeStoreToken } from '@/utils/auth'
import { removeStartPopupList } from '@/utils/startPopup'
import { removeVisibleFloatButtonList } from '@/utils/floatButton'
import { setUserInfo, setPermissions, removeUserInfo, removeloginSuc, removeUserType, removePermissions } from '@/utils/user'
import { getInfo } from '@/api/login'
import { getVisibleFloatButtonList, setVisibleFloatButtonList } from '@/utils/floatButton.js'
import { getPlatformSettingList, checkRedirect, getPlantformDetail } from '@/api/systemSetting'
import { setStartPopupList, getStartPopupList } from '@/utils/startPopup'
import { getPublishBaseUrl } from '@/utils/config.js'
export default {
  name: 'Home',
  computed: {
    ...mapGetters(['visibleFloatButton', 'token', 'userInfo']),
    visibleFloatButton() {
      if (this.floatData.length == 0) {
        return false
      } else {
        const { companyIdVuex, userId, visibleFloatButtonList } = this
        if (!companyIdVuex || !userId) {
          return false
        } else {
          const matchedItem = visibleFloatButtonList.find(
            item => item.companyId === Number(companyIdVuex) && item.userId == userId && this.floatId == item.id
          )
          return matchedItem ? matchedItem.visibleFloatButton : true
        }
      }
    },
    backgroundUrl() {
      if (this.floatData.length == 0) {
        return ''
      } else {
        return this.floatData[0].imgSrc
      }
    },
    visibleStartPopup() {
      if (this.popupList.length == 0) {
        return false
      } else {
        const { companyIdVuex, userId, startPopupList } = this
        if (!companyIdVuex || !userId) {
          return false
        } else {
          const matchedItem = startPopupList.find(
            item => item.companyId === Number(companyIdVuex) && item.userId == userId && this.startPopId == item.id
          )
          return matchedItem ? matchedItem.visibleStartPopup : true
        }
      }
    }
  },
  components: {
    CourseList,
    BannerBox
  },
  data() {
    return {
      list: [], //活动配置列表
      loading: false,
      floatData: [],
      floatId: null,
      popupList: [], //启动弹窗
      popupSrc: null,

      redirectId: null,
      id: null,
      startPopupList: [],
      startPopId: null,
      companyIdVuex: null,
      userId: null,
      visibleFloatButtonList: []
    }
  },

  created() {
    this.visibleFloatButtonList = getVisibleFloatButtonList() ? getVisibleFloatButtonList().visibleFloatButtonList : []
    this.startPopupList = getStartPopupList() ? getStartPopupList().startPopupList : []
    if (getToken() != undefined) {
      // this.getInfo()
    }

    this.getPlatformSetting()
  },

  methods: {
    ...mapActions({
      SET_USERINFO: 'SET_USERINFO',
      SET_PERMISSIONS: 'SET_PERMISSIONS',
      set_visibleFloatButton: 'SET_VISIBLEFloatBUTTON',
      SET_TOKEN: 'SET_TOKEN',
      SET_USERTYPE: 'SET_USERTYPE'
    }),
    // 获取用户详情
    getInfo() {
      getInfo()
        .then(res => {
          let user = res.data.user
          let permissionFlag = ['shopping:record:pay', 'order:list:pay', 'shopping:businessVoucher:list']
          let permissions = res.data.permissions.some(permission => {
            return '*:*:*' === permission || permissionFlag.includes(permission)
          })
          let { userName, companyName, userId, companyId } = res.data.user
          let avatar = user.avatar ?? require('@/assets/no-avatar.png')
          let userInfo = {
            name: userName,
            avatar: avatar,
            companyName,
            userId,
            companyId
          }
          this.SET_USERINFO(userInfo)
          this.SET_PERMISSIONS(permissions)
          setUserInfo(JSON.stringify(userInfo))
          setPermissions(JSON.stringify(permissions))
          setStoreToken(getToken())
          this.companyIdVuex = this.userInfo.companyId
          this.userId = this.userInfo.userId
        })
        .catch(err => {
          if (err.code == 401) {
            this.SET_TOKEN('')
            this.SET_USERTYPE('')
            removeToken()
            removeStoreToken()
            removeloginSuc()
            removeUserInfo()
            removeUserType()
            removePermissions()
            removeStartPopupList()
            removeVisibleFloatButtonList()
            console.log('fffff账号被顶掉了')
            // this.$router.go(0)
            this.$confirm('当前登录状态已失效，是否要重新登录', '提示', {
              confirmButtonText: '重新登录',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.$router.push('/loginStore')
              })
              .catch(() => {
                this.$router.go(0)
                console.log('取消了')
              })
            // location.href = getPublishBaseUrl()
            // location.href = 'http://localhost:8080'
          }
        })
    },

    // 拖拽的点击事件
    onClickFloatButton(value) {
      console.log('click')
      let obj = value.floatData[0]
      let { id, redirectLink, redirectType, redirectId } = obj
      this.id = id
      this.redirectId = redirectId
      this.switchJump(redirectType, redirectLink)
      // 和轮播图的点击事件一样 看type类型决定 跳转的页面
    },
    // 拖拽的关闭事件
    onCloseFloatButton() {
      this.setCacheFloatButtonList()
    },
    setCacheFloatButtonList() {
      let visibleFloatButtonList = this.visibleFloatButtonList
      if (getVisibleFloatButtonList()) {
        let index = visibleFloatButtonList.findIndex(
          item => item.companyId == this.companyIdVuex && this.userId == item.userId && item.id == this.floatId
        )
        if (index > -1) {
          visibleFloatButtonList[index].visibleFloatButton = false
        } else {
          visibleFloatButtonList.push({
            companyId: Number(this.companyIdVuex),
            userId: this.userId,
            visibleFloatButton: false,
            id: this.floatId
          })
        }
      } else {
        visibleFloatButtonList.push({
          companyId: Number(this.companyIdVuex),
          userId: this.userId,
          visibleFloatButton: false,
          id: this.floatId
        })
      }
      let flotButton = {
        visibleFloatButtonList: this.visibleFloatButtonList
      }
      setVisibleFloatButtonList(flotButton)
    },
    closeStartPopup() {
      this.handleStartPopup()
    },
    handleStartPopup() {
      if (this.startPopupList.length > 0) {
        let index = this.startPopupList.findIndex(
          item => item.companyId == this.companyIdVuex && this.userId == item.userId && item.id == this.startPopId
        )
        if (index > -1) {
          this.startPopupList[index].visibleStartPopup = false
        } else {
          this.startPopupList.push({
            companyId: Number(this.companyIdVuex),
            userId: this.userId,
            visibleStartPopup: false,
            id: this.startPopId
          })
        }
      } else {
        this.startPopupList.push({
          companyId: Number(this.companyIdVuex),
          userId: this.userId,
          visibleStartPopup: false,
          id: this.startPopId
        })
      }
      let startPopup = {
        startPopupList: this.startPopupList
      }
      setStartPopupList(startPopup)
    },
    // 启动弹窗跳转
    handlePopupJump() {
      let { id, redirectLink, redirectType, redirectId } = this.popupList[0]
      this.id = id
      this.redirectId = redirectId
      this.switchJump(redirectType, redirectLink)
    },
    switchJump(redirectType, redirectLink) {
      switch (redirectType) {
        case -1:
          break
        case 0:
          this.handleJump(redirectLink)
          break
        case 4:
          // 跳转商城的课程组详情页面
          this.onHandleGoGroup()
          break
      }
    },
    handleJump(url) {
      Promise.all([this.checkRedirect()]).then(res => {
        if (res[0].code == 200) {
          window.open(url, '_blank')
        }
      })
    },
    // 跳转课程组
    onHandleGoGroup() {
      Promise.all([this.checkRedirect()])
        .then(res => {
          if (res[0].code == 200) {
            this.$router.push({
              path: '/courseGroupDetail',
              query: { id: this.redirectId }
            })
            // window.open(getBaseUrl() + `/courseGroupDetail?token=${getToken()}&id=${this.redirectId}`)
          }
        })
        .catch(error => {})
    },
    async getPlatformSetting() {
      this.loading = true
      let res = await getPlatformSettingList({ activityTerminal: 'web_store' })
      this.list = res.data || []
      this.loading = false
      this.floatData = res.data.filter(item => item.activityPosition === 'float_icon')
      this.floatId = this.floatData.length > 0 ? this.floatData[0].id : null
      this.popupList = res.data.filter(item => item.activityPosition === 'popup')
      this.startPopId = this.popupList.length > 0 ? this.popupList[0].id : null

      this.popupSrc = this.popupList.length > 0 ? this.popupList[0].imgSrc : null
    },
    // 校验公告或者课程组是否可以跳转
    async checkRedirect() {
      return new Promise(async (resolve, reject) => {
        let res = await checkRedirect({ id: this.id })
        if (res.code == 200) {
          resolve(res)
        } else {
          this.$modal.msgError('活动设置不存在')
          this.getPlatformSettingList()
          reject(res)
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.home {
  position: relative;
  border: 1px solid transparent;
  // overflow-x: hidden;
  .home-banner {
    position: absolute;
    width: 100%;
  }
  &-content {
    position: relative;
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0 auto;
    // height: 600px;
    // border: 1px solid red;
    margin-top: $robin-navbar-height-web;
  }
}
</style>
