<template>
  <div class="nav-bar">
    <div class="navbar-content tail-navbar-content tail-flex">
      <div class="logo" @click="goIndex">
        <!-- v-if="!platformLogo" -->
        <span v-if="!platformLogo">企云巅</span>
        <span v-else>
          <img style=" height: 30px" :src="platformLogo" alt="" />
        </span>
      </div>
      <div class="search-login tail-flex">
        <div class="search">
          <img src="../../assets/search.png" alt="" />
          <div v-if="searchDownShow" class="search-down">
            <div @click="goPersonalCenter">个人中心</div>
            <div @click="logout">退出登录</div>
          </div>
        </div>
        <!-- <div class="new-people" v-if="showLogin">
          <div>新人</div>
          <div>送兑换券</div>
        </div> -->
        <div v-if="showLogin" class="login tail-flex">
          <div @click="goLogin">登录</div>
          <!-- <div class="line">/</div> -->
          <!-- <div>注册</div> -->
        </div>
        <div v-if="!showLogin" class="user-info tail-flex">
          <div class="user-web user tail-flex" @click="goPersonalCenter">
            <div ref="avatarRef" class="avatar">
              <img :src="userInfoNav.avatar" alt="" />
            </div>
            <div class="name">{{ userInfoNav.name }}</div>
          </div>
          <div class="user-app user tail-flex">
            <div ref="avatarRef" @click="showSearchDown" class="avatar">
              <img :src="userInfoNav.avatar" alt="" />
            </div>
          </div>
          <div class="log-out" @click="logout">退出登录</div>
        </div>
      </div>
      <!-- 遮罩层 -->
      <div v-if="searchDownShow" class="mask"></div>
    </div>
    <AffirmDialog :affirmDialogShow="affirmDialogShow" @submitDialog="submitDialog" @cancelDialog="cancelDialog">{{ contentText }}</AffirmDialog>
  </div>
</template>

<script>
import { getUserInfo, removeUserInfo, removeloginSuc, removeUserType, removePermissions } from '@/utils/user'
import { getPublishBaseUrl } from '@/utils/config.js'
import { removeToken, removeStoreToken, getToken } from '@/utils/auth'
import { logout } from '@/api/login'
import { mapActions, mapGetters } from 'vuex'
import { removeStartPopupList } from '@/utils/startPopup'
import { removeVisibleFloatButtonList } from '@/utils/floatButton'
import { getPlantformDetail } from '@/api/systemSetting'

export default {
  name: 'NavBar',
  computed: {
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      showLogin: true,
      searchDownShow: false,
      userInfoNav: {
        name: null,
        avatar: null
      },
      affirmDialogShow: false,
      contentText: '是否确认退出？',
      platformLogo: null
    }
  },
  watch: {
    userInfo: {
      handler(newVal) {
        if (newVal) {
          this.showLogin = false
          this.userInfoNav = { ...newVal }
        }
      },
      deep: true
    }
  },
  beforeDestory() {
    window.removeEventListener()
  },
  created() {
    if (getUserInfo() != undefined) {
      this.showLogin = false
      this.SET_USERINFO(JSON.parse(getUserInfo()))
    } else {
      this.showLogin = true
      let info = {
        name: null,
        avatar: null
      }
      this.SET_USERINFO(null)
    }
    if (getToken() != undefined) {
      this.getPlantformDetail()
    }

    // if (getUserInfo() != undefined) {
    //   this.showLogin = false
    //   this.userInfoNav = JSON.parse(getUserInfo())
    // } else {
    //   this.showLogin = true
    //   this.userInfoNav = this.userInfoNav
    // }
    window.addEventListener('click', this.otherAvatarClose)
  },
  methods: {
    ...mapActions(['SET_TOKEN', 'SET_USERTYPE', 'SET_USERINFO']),
    // 退出登录
    logout() {
      this.affirmDialogShow = true
    },
    otherAvatarClose(e) {
      if (this.$refs.avatarRef && !this.$refs.avatarRef.contains(e.target)) {
        this.searchDownShow = false
      }
    },
    async getPlantformDetail() {
      let res = await getPlantformDetail()
      this.platformLogo = res.data.platformLogo
    },
    submitDialog() {
      this.affirmDialogShow = false
      logout().then(() => {
        this.SET_TOKEN('')
        this.SET_USERTYPE('')
        removeToken()
        removeStoreToken()
        removeloginSuc()
        removeUserInfo()
        removeUserType()
        removePermissions()
        removeStartPopupList()
        removeVisibleFloatButtonList()
        this.searchDownShow = false
        // 为了防止回到首页，跳转到后台管理系统
        location.href = getPublishBaseUrl()
      })
    },
    cancelDialog() {
      this.affirmDialogShow = false
    },
    goIndex() {
      // 为了防止回到首页，跳转到后台管理系统
      // this.$router.replace('/')
      location.href = getPublishBaseUrl()
    },
    goLogin() {
      this.$router.push('/loginStore')
    },
    goPersonalCenter() {
      // this.$router.push('/personalCenter')
    },
    showSearchDown() {
      this.searchDownShow = !this.searchDownShow
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-bar {
  height: $robin-navbar-height-web;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  .navbar-content {
    max-width: 1200px;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    .logo {
      font-weight: 600;
      font-size: 24px;
      color: $robin-subject-font-color;
      cursor: pointer;
    }
    .search-login {
      .search {
        display: none;
      }
      .login {
        height: 42px;
        background: $robin-essential-color;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #fff;
        padding: 0px 15px;
        position: relative;
        cursor: pointer;
        .line {
          padding: 0 5px;
        }
      }
      .new-people {
        box-sizing: border-box;
        height: 42px;
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        padding: 3px 9px 7px 7px;
        background: linear-gradient(146deg, #ff9569 0%, #ff7a50 53%, #ff6542 100%);
        border-radius: 6px 6px 6px 6px;
        margin-right: 15px;
        font-size: 12px;
        div {
          background: linear-gradient(to bottom, #ffffff 0%, #ffe9b1 100%);
          color: transparent; /* 隐藏实际的文字颜色 */
          -webkit-background-clip: text; /* 将渐变应用到文字 */
          background-clip: text; /* 将渐变应用到文字 */
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          right: -10px;
          top: 50%;
          transform: translate(0, -50%);
          width: 0;
          height: 0;
          border-top: 5px solid transparent; /* 控制三角形的大小 */
          border-bottom: 5px solid transparent; /* 控制三角形的大小 */
          border-left: 10px solid #ff6542; /* 控制三角形的颜色和宽度 */
        }
      }
      .user-info {
        align-items: center;
        font-size: 18px;
        .user {
          margin-right: 22px;
          align-items: center;
          cursor: pointer;
          .avatar {
            width: 33px;
            height: 33px;
            border-radius: 50%;
            margin-right: 12px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .name {
            color: #333333;
          }
        }
        .user-app {
          display: none;
        }
        .log-out {
          color: #999999;
          cursor: pointer;
          font-size: 16px;
        }
      }
    }
  }
}
.tail-navbar-content {
  @apply mx-auto;
}
.tail-flex {
  @apply flex flex-wrap;
}
@media (max-width: 1200px) {
  .nav-bar {
    .navbar-content {
      padding: 0 1rem;
    }
  }
}
@media (max-width: 767px) {
  .nav-bar {
    height: $robin-navbar-height-app;
    .navbar-content {
      .logo {
        width: 80px;
        height: 24px;
      }
      .search-login {
        align-items: center;
        .search {
          width: 19px;
          height: 19px;
          margin-right: 25px;
          display: block;
          position: relative;
          img {
            display: none;
          }
          .search-down {
            z-index: 2;
            position: absolute;
            top: 33px;
            right: -66px;
            width: 103px;
            padding: 18px 17px 22px;
            height: 91px;
            background: #d8d8d8;
            color: #646a73;
            font-size: 14px;
            border-radius: 5px;
            text-align: center;
            div:first-child {
              padding-bottom: 6px;
              border-bottom: 1px solid #979797;
            }
            div:last-child {
              padding-top: 6px;
            }
          }
          .search-down::after {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-bottom: 5px solid #d8d8d8;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            top: -5px;
            right: 20px;
          }
        }
        .login {
          // width: 87px;
          // height: 26px;
          border-radius: 15px;
          font-size: 12px;
          div {
            width: 39px;
            height: 26px;
            line-height: 26px;
          }
          div:first-child {
            margin-right: 10px;
          }
          div:first-child::after {
            height: 10px;
            right: -5px;
            top: 7px;
          }
        }
        .user-info {
          align-items: center;
          font-size: 18px;
          .user {
            margin-right: 0;
            align-items: center;
            cursor: pointer;
            .avatar {
              width: 31px;
              height: 31px;
              border-radius: 50%;
              margin-right: 0;
            }
            // .name {
            //   display: none;
            // }
          }
          .user-app {
            display: block;
          }
          .user-web {
            display: none;
          }
          .log-out {
            display: none;
          }
        }
      }
      .mask {
        position: fixed;
        top: 48px;
        width: 100%;
        height: calc(100% - 48px);
        left: 0;
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
</style>
