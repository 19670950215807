<template>
  <div class="firm-form">
    <div class="title-box" v-if="titleBoxShow">
      <div class="title title-act">企业登录</div>
    </div>

    <div class="form-box">
      <div class="form-item" :class="errUserNameText == '' || errUserNameText == null ? '' : 'form-item-act'">
        <div class="form-item-cont">
          <div class="form-item-lable">账号</div>
          <div class="form-item-ipt">
            <input type="text" placeholder="请输入账号" v-model="form.username" @input="inputUsername" />
          </div>
          <img
            class="form-item-icon"
            src="../../../assets/clearInput.png"
            alt=""
            @click="clearInput"
            v-if="form.username !== '' && form.username !== null"
            style="top: 12px"
          />
        </div>
        <div class="form-item-text">{{ errUserNameText }}</div>
      </div>

      <div class="form-item" :class="errPasswordText == '' || errPasswordText == null ? '' : 'form-item-act'">
        <div class="form-item-cont">
          <div class="form-item-ipt">
            <input ref="passwordIpt" type="password" placeholder="请输入密码" v-model="form.passwordCopy" @input="inputPassword" />
          </div>
          <img class="form-item-icon" :src="iconEyes" alt="" @click="changeEyes" />
        </div>
        <div class="form-item-text">{{ errPasswordText }}</div>
      </div>

      <div class="code-box">
        <div class="form-item code-box-ipt" :class="errCodeText == '' || errCodeText == null ? '' : 'form-item-act'">
          <div class="form-item-cont">
            <div class="form-item-ipt">
              <input type="text" placeholder="验证码" v-model="form.code" @keyup.enter="submitForm" @input="inputCode" />
            </div>
          </div>
          <div class="form-item-text">{{ errCodeText }}</div>
        </div>
        <div class="form-item form-item-code" v-if="captchaOnOff">
          <img :src="codeUrl" alt="" @click="getCode" />
        </div>
      </div>

      <div class="sub-btn" :class="!subActive ? 'sub-btn-act' : ''" @click="submitForm">登录</div>

      <alert-msg :time="1500" :visibleMsg.sync="isShowErrMsg">{{ errMsgText }}</alert-msg>
    </div>
  </div>
</template>
<script>
import { getCodeImg, login, getInfo } from '@/api/login'
import { encrypt, decrypt } from '@/utils/jsencrypt'
import { setToken, setStoreToken } from '@/utils/auth'
import { setUserInfo, setloginSuc, setUserType, setPermissions } from '@/utils/user'
import { mapActions, mapGetters } from 'vuex'
import { debounce } from '@/utils/common.js'
export default {
  name: 'FirmForm',
  computed: {
    ...mapGetters(['equipmentType'])
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        let newValArr = Object.entries(newVal)
        this.subActive = newValArr.some((item, index) => {
          return item[1] === '' || item[1] === null
        })
      },
      deep: true
    },
    $route: {
      handler(newVal, oldVal) {
        if (newVal.query && newVal.query.redirect) {
          this.redirect = newVal.query.redirect
        }
      },
      deep: true,
      immediate: true
    },
    equipmentType: {
      handler: function (newVal, oldVal) {
        this.titleBoxShow = newVal == 'WEB' ? false : true
      },
      immediate: true
    }
  },
  data() {
    return {
      iconEyes: require('@/assets/closeEyes.png'),
      titleBoxShow: true,
      form: {
        username: null,
        password: null,
        passwordCopy: null,
        rememberMe: false, //记住密码
        code: null,
        uuid: null
      },
      errUserNameText: null,
      errPasswordText: null,
      errCodeText: null,
      subActive: true,
      errMsgText: null, //错误提示语
      isShowErrMsg: false,
      // 验证码开关
      captchaOnOff: true,
      codeUrl: '',
      redirect: null
    }
  },
  created() {
    this.getCode()
  },
  methods: {
    ...mapActions(['SET_TOKEN', 'SET_USERINFO', 'SET_USERTYPE', 'SET_PERMISSIONS']),
    // 获取验证码图片
    getCode() {
      getCodeImg().then(res => {
        this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff
        if (this.captchaOnOff) {
          this.codeUrl = 'data:image/gif;base64,' + res.data.img
          this.form.uuid = res.data.uuid
        }
      })
    },
    // 账号校验
    ruleAccount(value) {
      if (value !== '' && value !== null) {
        this.errUserNameText = ''
      } else {
        this.errUserNameText = '账号不能为空'
      }
    },
    // 密码校验
    rulePassword(value) {
      // let rule = /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9]{8,16}$/
      if (value !== '' && value !== null) {
        this.errPasswordText = ''
        // if (!rule.test(value)) {
        //   this.errPasswordText = '请输入正确的密码格式'
        // } else {
        //   this.errPasswordText = ''
        // }
      } else {
        this.errPasswordText = '密码不能为空'
      }
    },
    // 验证码校验
    ruleCode(value) {
      if (value !== '' && value !== null) {
        this.errCodeText = ''
      } else {
        this.errCodeText = '验证码不能为空'
      }
    },
    inputUsername() {
      this.form.username = this.form.username.replace(' ', '')
    },
    inputPassword() {
      this.form.passwordCopy = this.form.passwordCopy.replace(' ', '')
      this.form.password = this.form.passwordCopy
    },
    inputCode() {
      this.form.code = this.form.code.replace(' ', '')
    },
    // 获取用户详情
    getInfo() {
      return new Promise((resolve, reject) => {
        getInfo()
          .then(res => {
            let user = res.data.user
            let permissionFlag = ['shopping:record:pay', 'order:list:pay', 'shopping:businessVoucher:list']
            let permissions = res.data.permissions.some(permission => {
              return '*:*:*' === permission || permissionFlag.includes(permission)
            })
            let { userName, companyName, userId, companyId } = res.data.user
            let avatar = user.avatar ?? require('@/assets/no-avatar.png')
            let userInfo = {
              name: userName,
              avatar: avatar,
              companyName,
              userId,
              companyId
            }
            this.SET_USERINFO(userInfo)
            this.SET_PERMISSIONS(permissions)
            setUserInfo(JSON.stringify(userInfo))
            setPermissions(JSON.stringify(permissions))
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // 提交表单
    submitForm: debounce(function () {
      this.form.password = this.form.passwordCopy
      this.ruleAccount(this.form.username)
      this.rulePassword(this.form.password)
      this.ruleCode(this.form.code)
      let pass = this.errUserNameText === '' && this.errPasswordText === '' && this.errCodeText === '' ? true : false
      if (pass) {
        this.form.password = encrypt(this.form.password)
        let form = {
          rememberMe: this.form.rememberMe,
          username: this.form.username,
          password: this.form.password,
          code: this.form.code,
          uuid: this.form.uuid
        }
        login(form)
          .then(res => {
            setToken(res.data.token)
            setStoreToken(res.data.token)
            setloginSuc(true)
            setUserType('companyUser')
            this.SET_TOKEN(res.data.token)
            this.SET_USERTYPE('companyUser')
            Promise.all([this.getInfo()]).then(res => {
              this.$router.replace({ path: this.redirect || '/' }).catch(() => {})
            })
          })
          .catch(err => {
            this.errMsgText = err.msg
            this.isShowErrMsg = true
            setTimeout(() => {
              this.isShowErrMsg = false
            }, 1500)
            // this.form.password = decrypt(this.form.password)
            if (this.captchaOnOff) {
              this.getCode()
            }
          })
      }
    }, 500),
    changeEyes() {
      console.log('this.$refs.passwordIpt.type', this.$refs.passwordIpt.type)
      if (this.$refs.passwordIpt.type == 'password') {
        this.$refs.passwordIpt.type = 'text'
        this.iconEyes = require('@/assets/openEyes.png')
      } else if (this.$refs.passwordIpt.type == 'text') {
        this.$refs.passwordIpt.type = 'password'
        this.iconEyes = require('@/assets/closeEyes.png')
      }
    },
    clearInput() {
      this.$set(this.form, 'username', null)
    }
  }
}
</script>
<style lang="scss" scoped>
// @media (max-width: 767px) {
.title-box {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
  .title {
    font-weight: bold;
    color: #2b2f36;
    font-size: 14px;
  }
  .title-act {
    position: relative;
  }
  .title-act::after {
    position: absolute;
    content: '';
    width: 44px;
    height: 2px;
    background: $robin-essential-color;
    border-radius: 2px;
    bottom: -5px;
    left: 50%;
    margin-left: -22px;
  }
}
.form-box {
  position: relative;
  padding: 0 18px;
  margin: 0 auto;
  .form-item {
    width: 100%;
    height: 45px;
    background: #f2f2f2;
    border-radius: 23px;
    margin-bottom: 21px;
    .form-item-cont {
      padding: 13px 22px 12px;
      display: flex;
      position: relative;
      z-index: 99;
      // align-items: center;
      .form-item-lable {
        color: #373c41;
        font-size: 14px;
        margin-right: 30px;
        position: relative;
        min-width: 28px;
      }
      .form-item-lable::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background: #979797;
        top: 0;
        right: -15px;
      }
      .form-item-ipt {
        height: 20px;
        display: flex;
        align-items: center;
        width: calc(100% - 88px);
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 14px;
          background: #f2f2f2;
          color: #373c41 !important;

          &:-webkit-autofill {
            transition: background-color 5000s ease-out 0.5s;
          }
        }
        input::-ms-clear {
          display: none;
        }
        input::-ms-reveal {
          display: none;
        }
      }
      .form-item-icon {
        position: absolute;
        top: 17px;
        right: 22px;
      }
    }
    .form-item-text {
      padding-left: 27px;
      font-size: 12px;
      color: #ff0000;
    }
  }
  .form-item-act {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border: 1px solid $robin-essential-color;
      border-radius: 23px;
    }
  }
  .code-box {
    display: flex;
    justify-content: space-between;
    .code-box-ipt {
      width: 58%;
      .form-item-ipt {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
    .form-item-code {
      width: 38%;
      overflow: hidden;
      img {
        transform: scale(1.1);
        position: relative;
        top: -2px;
      }
    }
    .form-item {
      margin-bottom: 0;
    }
  }
  .sub-btn {
    height: 45px;
    background: #8e8e93;
    border-radius: 25px;
    text-align: center;
    line-height: 45px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 36px;
  }
  .sub-btn-act {
    background: $robin-essential-color;
  }
}
// }

@media (min-width: 768px) {
  .form-box {
    padding: 0;
    .form-item {
      margin-bottom: 24px;
      .form-item-cont {
        .form-item-lable {
          font-size: 16px;
          top: -2px;
        }
      }
    }
    .sub-btn {
      cursor: pointer;
    }
  }
}
</style>
