<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { getToken, setStoreToken, removeToken, removeStoreToken } from '@/utils/auth'
import { setUserInfo, setPermissions, getPermissions, removeUserInfo, removeloginSuc, removeUserType, removePermissions } from '@/utils/user'
import { getInfo } from '@/api/login'
import { removeStartPopupList } from '@/utils/startPopup'
import { removeVisibleFloatButtonList } from '@/utils/floatButton'
import { getPublishBaseUrl } from '@/utils/config.js'

export default {
  data() {
    return {
      screenWidth: null
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  watch: {
    screenWidth: {
      handler: function (val, oldVal) {
        if (val < 768) {
          this.set_equipmentType('APP')
        } else {
          this.set_equipmentType('WEB')
        }
      },
      immediate: true
    }
  },
  created() {
    if (getToken()) {
      console.log('APP---created-getInfo')
      this.getInfo() // 解决从后台管理系统某些页面跳转时，得不到用户信息的问题
    }
    // this.$router.beforeEach((to, from, next) => {
    //   console.log('1111', to, from)
    //   console.log('变了吗', getToken() != getStoreToken())
    //   let isUserId =
    //     getUserInfo() != undefined && Cookies.get('Admin-UserId') != undefined
    //       ? JSON.parse(getUserInfo()).userId != Cookies.get('Admin-UserId')
    //       : true
    //   // console.log("🚀 ~ isUserId:", isUserId, JSON.parse(getUserInfo()).userId, '----', Cookies.get('Admin-UserId'))
    //   if (getToken() != undefined && getStoreToken() != undefined && getToken() != getStoreToken() && isUserId) {
    //     if (from.path == '/loginStore' || from.query.isJupm) {
    //       next()
    //     } else {
    //       this.$alert('用户信息已变更，请获取最新用户数据！', '系统提示', {
    //         confirmButtonText: '确定',
    //         callback: action => {
    //           this.getInfo()
    //           next()
    //         }
    //       })
    //     }
    //   } else if (getToken() != undefined && getStoreToken() == undefined) {
    //     this.getInfo()
    //     next()
    //   } else {
    //     next()
    //   }
    // })
    if (getPermissions() != undefined) {
      this.SET_PERMISSIONS(JSON.parse(getPermissions()))
    }
  },
  mounted() {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  methods: {
    ...mapActions({
      set_equipmentType: 'SET_EQUIPMENTTYPE',
      SET_USERINFO: 'SET_USERINFO',
      SET_PERMISSIONS: 'SET_PERMISSIONS',
      SET_TOKEN: 'SET_TOKEN',
      SET_USERTYPE: 'SET_USERTYPE'
    }),
    // 获取用户详情
    getInfo() {
      getInfo()
        .then(res => {
          let user = res.data.user
          let permissionFlag = ['shopping:record:pay', 'order:list:pay', 'shopping:businessVoucher:list']
          let permissions = res.data.permissions.some(permission => {
            return '*:*:*' === permission || permissionFlag.includes(permission)
          })
          let { userName, companyName, userId } = res.data.user
          let avatar = user.avatar ?? require('@/assets/no-avatar.png')
          let userInfo = {
            name: userName,
            avatar: avatar,
            companyName,
            userId
          }
          this.SET_USERINFO(userInfo)
          this.SET_PERMISSIONS(permissions)
          setUserInfo(JSON.stringify(userInfo))
          setPermissions(JSON.stringify(permissions))
          setStoreToken(getToken())
        })
        .catch(err => {
          if (err.code == 401) {
            this.$confirm('当前登录状态已失效，是否要重新登录', '提示', {
              confirmButtonText: '重新登录',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.$router.push('/loginStore')
                this.SET_TOKEN('')
                this.SET_USERTYPE('')
                removeToken()
                removeStoreToken()
                removeloginSuc()
                removeUserInfo()
                removeUserType()
                removePermissions()
                removeStartPopupList()
                removeVisibleFloatButtonList()
              })
              .catch(() => {
                this.SET_TOKEN('')
                this.SET_USERTYPE('')
                removeToken()
                removeStoreToken()
                removeloginSuc()
                removeUserInfo()
                removeUserType()
                removePermissions()
                removeStartPopupList()
                removeVisibleFloatButtonList()
                // location.href = getPublishBaseUrl()
                // 如果账号被顶掉了，先移除token  在刷新页面  避免出现弹窗关闭不了的问题
                if (this.$route.query.token) {
                  window.location.href = getPublishBaseUrl()
                } else {
                  this.$router.go(0)
                }
              })
          }
        })
    }
  }
}
</script>
