


const StartPopupList = 'startPopupList'
export function getStartPopupList() {
  return JSON.parse(sessionStorage.getItem(StartPopupList))
}

export function setStartPopupList(data) {
  return sessionStorage.setItem(StartPopupList, JSON.stringify(data))
}

export function removeStartPopupList() {
  return sessionStorage.removeItem(StartPopupList)
}
