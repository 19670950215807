<template>
  <div class="login">
    <div class="login_content">
      <div class="title-logo" @click="goIndex">
        <img class="nav-logo" src="../../assets/nav-logo.png" alt="" />
        <!-- <img class="nav-logo-pc" src="../../assets/nav-logo-pc.png" alt="" /> -->
      </div>
      <login-content></login-content>
      <div class="login-footer-pc">Copyright © 2012-2024 山东汇智云屏网络科技有限公司 鲁ICP备2022005491号-2</div>
    </div>
  </div>
</template>
<script>
import { getPublishBaseUrl } from '@/utils/config.js'
import LoginContent from './components/loginContent.vue'
export default {
  name: 'Login',
  components: {
    LoginContent
  },
  data() {
    return {}
  },
  methods: {
    goIndex() {
      // this.$router.push('/')
      // 为了防止回到首页，跳转到后台管理系统
      location.href = getPublishBaseUrl()
    }
  }
}
</script>
<style scoped lang="scss">
// @media (max-width: 767px) {
.login {
  background: #373c41;
  padding-bottom: 39px;
  min-height: 100%;
  .login_content {
    .title-logo {
      padding: 28px 22px;
      .nav-logo {
        width: 94px;
        height: 28px;
        cursor: pointer;
        margin-left: 40px;
      }
      .nav-logo-pc {
        display: none;
      }
    }
    .login-footer-pc {
      display: none;
    }
  }
}
// }
@media (min-width: 768px) {
  .login {
    width: 100%;
    background: #ebedef;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
    .login_content {
      width: 939px;
      .title-logo {
        padding: 0;
        .nav-logo {
          height: 40px;
          width: 172px;
        }
      }
      .login-footer-pc {
        display: block;
        font-size: 12px;
        font-weight: 400;
        color: #767b81;
        text-align: center;
      }
    }
  }
}

// .box {
//   max-width: 767px;
//   padding: 0 30px;
//   margin: 20px auto 0;
//   .form-item {
//     width: 100%;
//     height: 45px;
//     background: #f2f2f2;
//     border-radius: 23px;
//     margin-bottom: 21px;
//     .form-item-cont {
//       padding: 13px 22px 12px;
//       display: flex;
//       position: relative;
//       // align-items: center;
//       .form-item-lable {
//         color: #373c41;
//         font-size: 14px;
//         margin-right: 30px;
//         position: relative;
//       }
//       .form-item-lable::after {
//         content: '';
//         position: absolute;
//         width: 1px;
//         height: 100%;
//         background: #979797;
//         top: 0;
//         right: -15px;
//       }
//       .form-item-ipt {
//         height: 20px;
//         display: flex;
//         align-items: center;
//         input {
//           height: 100%;
//           outline: none;
//           font-size: 14px;
//           background: #f2f2f2;
//           color: #373c41 !important;
//           //   -webkit-text-fill-color: #8E8E93;
//           &:-webkit-autofill {
//             transition: background-color 5000s ease-out 0.5s;
//           }
//         }
//       }
//       .form-item-icon {
//         position: absolute;
//         top: 17px;
//         right: 22px;
//       }
//     }
//   }
//   .code-box {
//     display: flex;
//     justify-content: space-between;
//     .code-box-ipt {
//       width: 58%;
//       input {
//         max-width: 80px;
//       }
//     }
//     .form-item-code {
//       width: 38%;
//     }
//   }
// }
</style>
