<template>
  <div class="course-group">
    <div class="course-group-banner">
      <div class="course-group-content">
        <div class="course-group-banner-left">
          <div class="course-group-icon">课程组</div>
          <div class="course-group-title">{{ groupObject.topic }}</div>
          <div class="course-group-voucher">
            {{ groupObject.classifyNames }}
          </div>
          <div class="course-prise">
            <div class="course-prise-box">
              <div class="course-prise-number">
                <img src="../../assets/jindou.png" alt="" class="jindou" />
                <span v-if="groupObject.discountsPrice && groupObject.discountsPrice !== groupObject.comPayPrice">
                  {{ groupObject.discountsPrice }}
                </span>
                <span :class="groupObject.discountsPrice && groupObject.discountsPrice !== groupObject.comPayPrice ? 'act-prise' : ''">
                  {{ groupObject.comPayPrice }}
                </span>
              </div>
              <div
                class="course-prise-interests"
                v-if="(appendItemList.length && appendItemList.length > 0) || (appendNoItemList.length && appendNoItemList.length > 0)"
              >
                <img
                  style="width: 29px; height: 29px"
                  src="../../assets/qiye-icon.png"
                  v-if="groupObject.discountsList[0].discountType == 2"
                  alt=""
                />
                <img
                  style="width: 29px; height: 29px"
                  src="../../assets/pingtai-icon.png"
                  v-if="groupObject.discountsList[0].discountType == 1"
                  alt=""
                />
                <span class="course-prise-interests-text">{{ groupObject.discountsList[0].discountName }}</span>
                <!-- <span class="course-prise-interests-text">{{appendItemList.length && appendItemList.length > 0 ? appendItemList[0].discountName : appendNoItemList[0].discountName}}</span> -->
                <span>等{{ appendItemList.length + appendNoItemList.length }}项优惠可选</span>
              </div>
            </div>
            <div class="group-vocher-box-in">
              <div v-for="(item, index) in groupObject.groupVouchers" :key="index" class="group-vocher-box">
                <img :src="item.voucherIcon" alt="" class="course-prise-img" />
                <span class="course-prise-number">
                  {{ item.voucherCount }}
                </span>
              </div>
            </div>
          </div>
          <div class="course-number">
            {{ groupObject.courseCount }}门课 每个课程可分配给{{ groupObject.comAllotNum }}人学习,有效期{{ groupObject.validDays }}天
          </div>
        </div>
        <div class="course-group-banner-right">
          <img :src="groupObject.coverPicture" alt="" class="right-group-img" />
          <div class="go-pay" @click="goConfirmOrder">立即购买</div>
        </div>
      </div>
    </div>
    <div class="course-group-detail">
      <div class="detail-tab">
        <el-tabs v-model="activeName" justify="center">
          <el-tab-pane
            :label="`课程(${groupObject.courseList.length})`"
            name="1"
            v-if="groupObject.courseList && groupObject.courseList.length > 0"
          ></el-tab-pane>
          <el-tab-pane label="详情" name="2"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="course-group-detail-list" v-if="activeName == '1'">
        <template v-if="groupObject.courseList && groupObject.courseList.length > 0">
          <div class="detail-list-item" v-for="item in groupObject.courseList" :key="item.id" @click="onHandelDetailCourse(item.id)">
            <img :src="item.picture" alt="" />
            <div class="detail-list-item-title">{{ item.courseName }}</div>
            <div class="detail-list-item-leture">
              <span>{{ item.lecturerName }}</span>
              <span>{{ item.lessonCount }}节课</span>
            </div>
          </div>
        </template>
        <NoData v-else>
          <template slot="text">暂无数据</template>
        </NoData>
      </div>
      <div class="course-group-text ql-snow" v-if="activeName == '2'">
        <div v-html="groupObject.introduce" class="ql-editor"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGroupInfo, getSelectDiscounts } from '@/api/courseGroupDetail.js'
import { getDiscountPrice } from '@/utils/common'
import {getToken, removeToken, removeStoreToken } from '@/utils/auth'
import { removeStartPopupList } from '@/utils/startPopup'
import { removeVisibleFloatButtonList } from '@/utils/floatButton'
import { removeUserInfo, removeloginSuc, removeUserType, removePermissions } from '@/utils/user'
import { getInfo } from '@/api/login'
import { mapActions } from 'vuex'
export default {
  name: 'CourseGroupDetail',
  data() {
    return {
      activeName: '1',
      id: '',
      groupObject: {},
      appendAllList: [], // 可叠加
      appendNoAllList: [], // 不可叠加
      appendItemList: [], //当前课程可叠加折扣
      appendNoItemList: [] //当前课程不可叠加折扣
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getGroupInfo(this.$route.query.id)
  },
  methods: {
    ...mapActions({
      SET_TOKEN: 'SET_TOKEN',
      SET_USERTYPE: 'SET_USERTYPE'
    }),
    async getGroupInfo() {
      try {
        let res = await getGroupInfo(this.id)
        this.groupObject = res.data
        this.groupObject.introduce = this.showHtml(this.groupObject.introduce)
        await this.getSelectDiscounts()
        this.appendItemList = this.appendAllList.filter(item => item.goodsId == this.groupObject.id)
        this.appendNoItemList = this.appendNoAllList.filter(item => item.goodsId == this.groupObject.id)
        this.$set(
          this.groupObject,
          'discountsPrice',
          getDiscountPrice(this.appendAllList, this.appendNoAllList, this.groupObject.id, this.groupObject.comPayPrice).showNum
        )
        this.$set(
          this.groupObject,
          'discountsList',
          getDiscountPrice(this.appendAllList, this.appendNoAllList, this.groupObject.id, this.groupObject.comPayPrice).showList
        )
      } catch (error) {
        this.$message.error(error.msg || '课程组详情加载失败！')
      }
    },
    // 获取商品折扣
    async getSelectDiscounts() {
      try {
        let res = await getSelectDiscounts()
        this.appendAllList = res.data.filter(item => item.appendFlag == 1)
        this.appendNoAllList = res.data.filter(item => item.appendFlag == 0)
      } catch (error) {}
    },
    onHandelDetailCourse(id) {
      this.$router.push({
        path: '/courseDetails',
        query: { id: id, backId: this.id }
      })
    },
    goConfirmOrder() {
      // 如果有token是被顶掉的  没token是未登录的
      if (getToken()) {
        getInfo()
          .then(res => {
            this.$router.push({
              path: '/confirmOrder',
              query: { id: this.id }
            })
          })
          .catch(err => {
            if (err.code == 401) {
              this.$confirm('当前登录状态已失效，是否要重新登录', '提示', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then(() => {
                  this.$router.push({
                    path: '/loginStore',
                    query: {
                      redirect: `/confirmOrder?id=${this.id}`
                    }
                  })
                  this.SET_TOKEN('')
                  this.SET_USERTYPE('')
                  removeToken()
                  removeStoreToken()
                  removeloginSuc()
                  removeUserInfo()
                  removeUserType()
                  removePermissions()
                  removeStartPopupList()
                  removeVisibleFloatButtonList()
                })
                .catch(() => {
                  this.SET_TOKEN('')
                  this.SET_USERTYPE('')
                  removeToken()
                  removeStoreToken()
                  removeloginSuc()
                  removeUserInfo()
                  removeUserType()
                  removePermissions()
                  removeStartPopupList()
                  removeVisibleFloatButtonList()
                  this.$router.go(0)
                })
            }
          })
      } else {
        this.$router.push({
          path: '/confirmOrder',
          query: { id: this.id }
        })
      }
    },
    showHtml(str) {
      return str.replace(/<img/g, '<img style="max-width:100%;height:auto;display:inline-block;margin:10px 0;"')
    }
  }
}
</script>

<style lang="scss" scoped>
.course-group {
  width: 100%;
  margin-top: $robin-navbar-height-web;
  &-banner {
    width: 100%;
    min-height: 388px;
    background: url('../../assets/course-group-bac.png') no-repeat 100% 100%;
    background-size: cover;
    .course-group-content {
      display: flex;
      width: 1200px;
      height: 100%;
      margin: auto;
      position: relative;
      padding-bottom: 30px;
      .course-group-banner-left {
        width: 50%;
        .course-group-icon {
          width: 76px;
          height: 32px;
          line-height: 32px;
          background: url('../../assets/course-group-icon.png') no-repeat 100% 100%;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          margin-top: 28px;
        }
        .course-group-title {
          font-weight: 600;
          font-size: 30px;
          color: #ffffff;
          margin-top: 20px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .course-group-voucher {
          box-sizing: border-box;
          display: inline-block;
          background: #fffbf4;
          border-radius: 4px 4px 4px 4px;
          padding: 6px 8px;
          font-weight: 500;
          font-size: 14px;
          color: #9c8056;
          margin-top: 20px;
          word-break: break-all;
        }
        .course-prise {
          margin-top: 50px;
          display: flex;
          // align-items: center;
          flex-direction: column;
          // flex-wrap: wrap;
          // border: 1px solid red;
          .course-prise-box {
            margin-bottom: 20px;
            display: flex;
            .course-prise-number {
              display: flex;
              margin-right: 35px;
              align-items: center;
              .jindou {
                width: 22px;
                height: 22px;
                margin-right: 5px;
              }
              span {
                display: inline-block;
                font-weight: 500;
                font-size: 30px;
                color: #ffffff;
                height: 25px;
                display: flex;
                align-items: center;
              }
              .act-prise {
                position: relative;
                font-weight: 400;
                font-size: 24px;
                color: rgba(255, 255, 255, 0.6);
                margin-left: 8px;
                &:before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 0;
                  width: 105%;
                  height: 1px;
                  background: rgba(255, 255, 255, 0.6);
                }
              }
            }
            .course-prise-interests {
              width: 310px;
              font-size: 14px;
              color: #fff;
              display: flex;
              align-items: center;
              img {
                margin-right: 7px;
              }
              .course-prise-interests-text {
                display: inline-block;
                max-width: 160px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .group-vocher-box-in {
            width: 70%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .group-vocher-box {
              display: flex;
              align-items: center;
              margin-top: 5px;
              .course-prise-img {
                width: 42px;
                height: 24px;
                margin-left: 14px;
                margin-right: 10px;
                object-fit: contain;
              }
              .course-prise-number {
                font-weight: 500;
                font-size: 24px;
                color: #ffffff;
                border-right: 1px solid #fff;
                padding-right: 10px;
                height: 25px;
                line-height: 25px;
              }
              &:first-child {
                .course-prise-img {
                  margin-left: 0;
                }
              }
            }
            .group-vocher-box:last-child .course-prise-number {
              border: none;
            }
          }
        }
        .course-number {
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
          margin-top: 55px;
        }
      }
      .course-group-banner-right {
        box-sizing: border-box;
        width: 431px;
        height: 396px;
        background: #ffffff;
        box-shadow: 0px 4px 5px 0px rgba(206, 206, 206, 0.25);
        border-radius: 10px 10px 10px 10px;
        position: absolute;
        top: 38px;
        right: 0;
        padding: 30px;
        .right-group-img {
          width: 100%;
          height: 246px;
          border-radius: 10px 10px 10px 10px;
        }
        .go-pay {
          width: 100%;
          height: 60px;
          line-height: 60px;
          text-align: center;
          background: linear-gradient(185deg, #3c9ffa 0%, #007cf9 100%);
          border-radius: 6px 6px 6px 6px;
          margin-top: 30px;
          font-weight: 600;
          font-size: 24px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  &-detail {
    width: 1200px;
    // min-height: 800px;
    margin: 0 auto;
    margin-top: 70px;
    // background: #f4f7f9;
    .detail-tab {
      ::v-deep .el-tabs__nav-scroll {
        display: flex;
        justify-content: center;
      }
      ::v-deep .el-tabs__item {
        font-weight: 400;
        font-size: 24px;
        color: #666666;
        padding: 10px 20px;
        height: auto;
        line-height: none;
      }
      ::v-deep .is-active {
        font-weight: 600;
        font-size: 24px;
        color: #252525;
      }
      ::v-deep .el-tabs__active-bar {
        width: 48px;
        height: 3px;
      }
    }
    .course-group-detail-list {
      display: flex;
      flex-wrap: wrap;
      .detail-list-item {
        cursor: pointer;
        padding: 8px;
        width: 23%;
        margin-bottom: 30px;
        margin-right: 2%;
        background: #fff;
        box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.11);
        border-radius: 10px;
        img {
          width: 100%;
          height: 152px;
          border-radius: 10px;
        }
        &-title {
          font-weight: 600;
          font-size: 16px;
          color: #252525;
          margin-top: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        &-leture {
          display: flex;
          justify-content: space-between;
          font-weight: 400;
          font-size: 14px;
          color: #333333;

          span:nth-child(1) {
            display: inline-block;
            width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
          span:nth-child(2) {
            display: inline-block;
            width: 30%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
            text-align: right;
          }
        }
      }
    }
    .course-group-text {
      // 解决富文本中图片不能居中的问题
      ::v-deep .ql-editor img {
        display: inline-block; /* 或者 block */
      }
    }
  }
}
</style>
<style></style>
