<template>
  <div class="course-list tail-course-list">
    <div>
      <TagBox :tagList="tagList" @getTagId="getTagId" :activeIndex="activeIndex"></TagBox>
    </div>
    <div class="list tail-list" :class="total > 20 ? '' : 'list-padding'" v-if="(!loadingShow && equipmentType == 'WEB') || equipmentType == 'APP'">
      <!-- 只有首页有 -->
      <div class="recommend-course" v-if="activeIndex == 0">
        <div class="hot-course">
          <div class="title">热卖好课</div>
          <div
            class="course-list-recommend"
            :style="{
              justifyContent: homeHotList.length > 1 ? 'space-around' : 'flex-start'
            }"
          >
            <div class="course-list-item" v-for="(item, index) in homeHotList" :key="index" @click="onHandleHot(item)">
              <img :src="item.coverPicture" alt="" />
              <div class="course-list-item-right">
                <span>{{ item.topic }}</span>
                <span>共{{ item.courseCount }}个课程</span>
              </div>
            </div>
            <NoData v-if="homeHotList.length == 0" class="no-data-hot">
              <template slot="text">暂无数据</template>
            </NoData>
          </div>
        </div>
        <div class="recommend-hot-course" @click="onHandleBanner">
          <img :src="homeBannerImg" alt="" srcset="" v-if="homeBannerImg" />
          <img src="../../../assets/default-banner.png" alt="" srcset="" v-else />
        </div>
      </div>
      <div class="item tail-item" v-for="(item, index) in courseList" :key="index" @click="goCourseDetails(item.id)">
        <div class="course-img-box">
          <img :src="item.coverPicture" class="tail-img course-img" alt="" srcset="" v-if="item.pictureImgExists" />
          <img src="../../../assets/placeholder.png" alt="" class="tail-img course-img" v-else />
          <span
            class="course-mark"
            :class="item.discountsList && item.discountsList.length > 0 && item.discountsList[0].discountType == 1 ? 'course-mark2' : ''"
            v-if="item.discountsPrice && item.discountsPrice !== item.comPayPrice"
          >
            {{ item.discountsList[0].discountName }}
          </span>
          <!-- 0购买 1兑换 2分配 3套餐。没有为未购买 -->
          <!-- <span class="have" v-if="item.purchaseSource !== 3 && item.purchaseSource !== undefined && userType === 'companyUser'">已购</span> -->
        </div>
        <div class="item-right tail-item-right">
          <div class="item-title tail-item-title">
            {{ item.topic }}
          </div>
          <div class="item-money tail-item-money">
            <span class="chapter tail-chapter"></span>
            <div class="money-box">
              <img :src="item.groupVouchers[0].voucherIcon" alt="" class="voucher-img" v-if="item.groupVouchers && item.groupVouchers.length > 0" />
              <div class="voucher-number" v-if="item.groupVouchers && item.groupVouchers.length > 0">{{ item.groupVouchers[0].voucherCount }}</div>
              <span style="color: #d1d1d1; font-size: 13px; margin-right: 5px" v-if="item.groupVouchers && item.groupVouchers.length > 0">|</span>
              <span class="money">
                <img src="../../../assets/jindou.png" alt="" class="jindou" />
                {{ item.discountsPrice ? item.discountsPrice : item.comPayPrice }}
              </span>
              <img
                style="width: 35px; height: 18px; margin-left: 5px"
                src="../../../assets/zhe.png"
                alt=""
                v-if="item.discountsPrice && item.discountsPrice !== item.comPayPrice"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- (noDataShow && activeIndex == 0 && !homeBannerData.courseGroups) || -->
    <NoData v-if="noDataShow && activeIndex !== 0">
      <template slot="text">暂无数据</template>
    </NoData>

    <ToLoading :loadingShow="loadingShow" :noLoadingTextShow="noLoadingTextShow"></ToLoading>

    <div class="pagination">
      <pagination v-show="total > 20" :total="total" :page.sync="pageNum" :limit.sync="pageSize" @pagination="getList" />
    </div>
    <!-- 回到顶部按钮 -->
    <div class="back-top" id="toTop" @click="clickHandler" v-if="visibleBackTop">
      <img src="../../../assets/back-top.png" alt="" srcset="" class="back-top-img" />
    </div>
  </div>
</template>

<script>
import { getListByParent } from '@/api/home'
import { mapGetters, mapActions } from 'vuex'
import { getUserType } from '@/utils/user.js'
import { checkImgExists, getDiscountPrice } from '@/utils/common'
import { homeBanner, recommendList, webCourseGroupList, getSelectDiscounts } from '@/api/courseGroupDetail.js'
export default {
  name: 'CourseList',
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 20,
      courseListLength: 20, //和pageSize初始化保持一致
      visibleBackTop: false,
      tagList: [],
      classifyId: '', //分类id
      activeIndex: 0,
      courseList: [],
      requestCompleted: true, // 请求是否完成
      // ToLoadingShow: false, // 是否显示loading组件
      loadingShow: false, // 是否显示loading
      noLoadingTextShow: false, // 是否显示'到底了'
      noDataShow: false,
      bannerParams: {
        classifyId: 0
      },
      homeBannerImg: '',
      homeBannerData: {},
      recommendParams: {
        classifyId: 0,
        recommendKey: 'home_banner_hot' //key 值 ：home_recommend ( 底部列表推荐 )key 值 ：home_banner_hot（顶部左边热卖）
      },
      homeHotList: [],
      recommendOtherParams: {
        classifyId: 0,
        recommendKey: 'home_recommend',
        pageNum: 1,
        pageSize: 20
      },
      webOtherParams: {
        pageNum: 1,
        pageSize: 20
      },
      appendAllList: [], // 可叠加
      appendNoAllList: [] // 不可叠加
    }
  },
  watch: {
    equipmentType: {
      handler: function (newVal, oldVal) {
        // this.ToLoadingShow = newVal == 'APP' ? true : false
        if ((newVal == 'WEB' && oldVal == 'APP') || (newVal == 'APP' && oldVal == 'WEB')) {
          location.reload()
        }
        // 避免pc和手机相互切换时数据页面乱的问题
        this.pageNum = 1
      },
      immediate: true
    }
  },
  created() {
    this.getCookieInfo()
    Promise.all([this.getListByParent(), this.getSelectDiscounts()]).then(res => {
      if (this.activeIndex == 0) {
        // tab 首页要调用的接口
        this.homeBanner()
        this.recommendList()
        this.getList()
      } else {
        this.getList()
      }
    })
    if (this.equipmentType == 'APP') {
      // 分页
      window.addEventListener('scroll', this.handleScroll, true)
      // 回到顶部
      window.addEventListener('scroll', this.scrollHandler)
    }
  },
  destroyed: function () {
    if (this.equipmentType == 'APP') {
      window.removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('scroll', this.scrollHandler)
    }
  },
  methods: {
    ...mapActions({
      set_userType: 'SET_USERTYPE'
    }),
    //获取首页banner图
    async homeBanner() {
      let res = await homeBanner(this.bannerParams)
      if (res.data) {
        this.homeBannerData = res.data
        this.homeBannerImg = res.data.recommendImg
      }
      // ? res.data.recommendImg
      // : this.homeBannerData.courseGroups && this.homeBannerData.courseGroups.length > 0
      // ? this.homeBannerData.courseGroups[0].coverPicture
      // : ''
    },
    // 获取热卖好课
    async recommendList() {
      let res = await recommendList(this.recommendParams)
      this.homeHotList = res.rows.slice(0, 2)
    },
    // 获取首页其余全部课程
    async getList() {
      this.recommendOtherParams.pageNum = this.pageNum
      this.recommendOtherParams.pageSize = this.pageSize
      this.webOtherParams.pageNum = this.pageNum
      this.webOtherParams.pageSize = this.pageSize
      this.requestCompleted = false
      this.loadingShow = true
      this.noDataShow = false
      try {
        let res
        if (this.activeIndex !== 0) {
          this.webOtherParams.classifyId = this.classifyId
          res = await webCourseGroupList(this.webOtherParams)
        } else {
          res = await recommendList(this.recommendOtherParams)
        }
        if (res?.rows.length > 0) {
          res.rows.forEach(async item => {
            item.pictureImgExists = true
          })
        }
        this.courseListLength = res?.rows.length || 0
        this.noLoadingTextShow = this.courseListLength < this.pageSize ? true : false
        if (this.equipmentType == 'APP') {
          // setTimeout(() => {
          // this.courseList = []
          this.courseList = this.courseList.concat(res.data?.list || [])
          if (this.courseList.length > 0) {
            this.loadingShow = false
          } else {
            this.noDataShow = true
            this.loadingShow = false
            this.noLoadingTextShow = false
          }
          // }, 3000)
        } else {
          this.courseList = res?.rows || []
          this.loadingShow = false
          this.noLoadingTextShow = false
          this.noDataShow = this.courseList.length > 0 ? false : true
        }
        this.courseList.forEach(async item => {
          item.pictureImgExists = await checkImgExists(item.coverPicture)
          // await this.getSelectDiscounts()
          this.$set(item, 'discountsPrice', getDiscountPrice(this.appendAllList, this.appendNoAllList, item.id, item.comPayPrice).showNum)
          this.$set(item, 'discountsList', getDiscountPrice(this.appendAllList, this.appendNoAllList, item.id, item.comPayPrice).showList)
        })
        this.total = res.total
        this.requestCompleted = true
      } catch (error) {
        this.requestCompleted = true
        this.loadingShow = false
      }
    },
    // 获取商品折扣
    getSelectDiscounts() {
      return new Promise(async resolve => {
        let res = await getSelectDiscounts()
        this.appendAllList = res.data.filter(item => item.appendFlag == 1)
        this.appendNoAllList = res.data.filter(item => item.appendFlag == 0)
        resolve()
      })
    },
    // 获取全部课程分类
    getListByParent() {
      return new Promise(async resolve => {
        let res = await getListByParent()
        this.tagList = []
        this.classifyId = res.data[0].id
        res.data.forEach((item, index) => {
          this.tagList.push({
            index,
            label: item.classifyName,
            id: item.id,
            orderNum: item.orderNum,
            parentId: item.parentId
          })
        })
        resolve()
      })
    },
    getTagId(value) {
      const { id, index } = value
      this.classifyId = id
      this.activeIndex = index
      this.courseList = []
      this.pageNum = 1
      this.total = 0
      this.courseListLength = this.pageSize
      if (this.activeIndex == 0) {
        // tab 首页要调用的接口
        this.homeBanner()
        this.recommendList()
        this.getList()
      } else {
        this.getList()
      }
    },
    goCourseDetails(id) {
      // this.$router.push({
      //   path: '/courseDetails',
      //   query: { id: id }
      // })
      this.$router.push({
        path: '/courseGroupDetail',
        query: { id: id }
      })
    },
    handleScroll() {
      if (this.$route.path !== '/') return // 解决再别的页面触发
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // const windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      const windowHeight = window.innerHeight
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      // 滚动条到底部的条件
      if (scrollTop > 0) {
        if (scrollHeight - (scrollTop + windowHeight) < 1) {
          if (this.courseListLength < this.pageSize) {
            return
          }
          if (this.equipmentType == 'APP') {
            console.log('app')
            if (this.requestCompleted) {
              this.requestCompleted = false
              this.loadingShow = true
              // 上次请求完成后，再调用下次请求
              setTimeout(() => {
                this.pageNum = this.pageNum + 1
                this.getList()
              }, 500)
            }
          } else {
            console.log('pc')
            this.loadingShow = false
          }
        }
      }
    },
    scrollHandler(e) {
      var distanceY = document.documentElement.scrollTop || document.body.scrollTop //兼容写法，获取当前页面y轴的滚动距离
      if (distanceY > 500) {
        this.visibleBackTop = true
      } else {
        this.visibleBackTop = false
      }
    },
    clickHandler(e) {
      let timer = setInterval(function () {
        var distanceY = document.documentElement.scrollTop || document.body.scrollTop //兼容
        if (distanceY == 0) {
          clearInterval(timer)
          return
        }
        // var speed = Math.ceil(distanceY / 16) //speed这个值从高变低，那么scrollTop就减得从快到慢，上回到顶部的速度就先快后慢
        // document.documentElement.scrollTop = distanceY - speed
        // document.documentElement.scrollTop = distanceY - 5 //如果给速度一个确定的值，那回到顶部的就匀速
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      }, 16)
    },
    // 获取cookie信息
    getCookieInfo() {
      if (getUserType()) {
        this.set_userType(getUserType())
      }
    },
    // 跳转到热卖好课-课程组详情
    onHandleHot(value) {
      let { id } = value
      this.$router.push({
        path: '/courseGroupDetail',
        query: { id: id }
      })
    },
    onHandleBanner() {
      if (this.homeBannerData.courseGroups.length > 0) {
        this.$router.push({
          path: '/courseGroupDetail',
          query: {
            id: this.homeBannerData.courseGroups && this.homeBannerData.courseGroups.length > 0 ? this.homeBannerData.courseGroups[0].id : ''
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters(['equipmentType', 'userType'])
  }
}
</script>

<style scoped lang="scss">
.course-list {
  box-sizing: border-box;
  width: 100%;
  margin-top: 200px;
  .list-padding {
    padding-bottom: 20px;
  }
  .list {
    box-sizing: border-box;
    width: 100%;
    .recommend-course {
      position: relative;
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      height: 312px;
      .no-data-hot {
        padding: 0;
      }
      .hot-course {
        height: 278px;
        width: 32%;
        margin-right: 19px;
        border-radius: 10px 10px 10px 10px;
        padding: 16px 8px;
        background: #ffffff;
        margin-top: 34px;
        .title {
          font-weight: 600;
          font-size: 24px;
          color: #333333;
          margin-bottom: 5px;
        }
        .course-list-recommend {
          height: calc(100% - 40px);
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .course-list-item {
            display: flex;
            cursor: pointer;
            img {
              max-width: 139px;
              min-width: 139px;
              height: 80px;
              border-radius: 10px 10px 10px 10px;
            }
            &-right {
              margin-left: 14px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              span {
                display: inline-block;
              }
              span:nth-child(1) {
                font-weight: 500;
                font-size: 20px;
                color: #333333;
                display: -webkit-box;
                word-break: break-all;
                -webkit-line-clamp: 2; /* 指定最多显示几行 */
                -webkit-box-orient: vertical;
                overflow: hidden; /* 隐藏超出部分的文本 */
                text-overflow: ellipsis; /* 显示省略号 */
                word-wrap: break-word; /* 确保长单词不会溢出 */
                white-space: normal; /* 允许文本换行 */
              }
              span:nth-child(2) {
                font-weight: 400;
                font-size: 18px;
                color: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
      .recommend-hot-course {
        width: calc(100% - 32% - 19px);
        height: 312px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          cursor: pointer;
        }
      }
    }
    .item {
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.11);
      border-radius: 8px;
      cursor: pointer;
      padding: 8px;
      .course-img-box {
        position: relative;
        .have {
          display: inline-block;
          position: absolute;
          right: 0px;
          top: 0px;
          color: #ffffff;
          background: #38af4a;
          border-radius: 0px 4px 0px 4px;
          font-size: 12px;
          padding: 2px 5px;
        }
        .course-img {
          object-fit: fill;
        }
        .course-mark {
          position: absolute;
          top: 0;
          right: 0;
          width: 90px;
          height: 25px;
          padding: 0 10px;
          line-height: 25px;
          text-align: center;
          font-size: 14px;
          color: #4d1e0a;
          font-weight: bold;
          background: linear-gradient(180deg, #ffe8af 0%, #f9c270 100%);
          border-top-right-radius: 6px;
          border-bottom-left-radius: 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .course-mark2 {
          color: #ffffff;
          background: linear-gradient(180deg, #fd7f7f 0%, #ee2626 100%);
        }
      }

      &-right {
        position: relative;
        .item-title {
          color: #252525;
          font-size: 16px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .item-money {
          .money-box {
            display: flex;
            align-items: center;
            .voucher-img {
              width: 22px;
              height: 12px;
              margin-right: 2px;
              object-fit: contain;
            }
            .money {
              font-weight: 600;
              font-size: 18px;
              color: #f0314a;
              display: flex;
              align-items: center;
              .jindou {
                width: 13px;
                height: 12px;
                margin-right: 5px;
              }
            }

            .chapter {
              color: #373c41;
              font-size: 12px;
            }
            .voucher-number {
              margin-right: 6px;
              font-weight: 600;
              font-size: 14px;
              color: #333333;
            }
          }
        }
      }
    }
  }
  .pagination {
    width: 100%;
    text-align: center;
    display: none;
  }
  .back-top {
    display: block;
    position: fixed; /*使用固定定位*/
    bottom: 185px;
    right: 19px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background: rgb(255, 255, 255, 0.9);
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    text-align: center;
    .back-top-img {
      width: 26px;
      height: 16px;
      display: inline-block;
    }
  }

  .tail-list {
    @apply flex flex-wrap items-center;
  }
  .tail-item {
    //  w-[calc(100%+2rem)]
    @apply flex  md:flex-col flex-row items-center md:w-[calc(25%-1rem)] w-full h-28 md:h-64 mb-3  p-3 md:p-0 md:items-start md:mr-4 md:rounded-lg rounded;
  }
  .tail-img {
    @apply w-40 h-24 rounded-lg md:w-full md:h-40;
  }
  .tail-item-right {
    @apply w-[calc(100%-10rem)] md:w-full h-full  flex flex-col justify-between pl-2 md:pl-4 md:pr-3 md:pt-2 md:pb-2;
  }
  .tail-item-title {
    @apply h-10 text-sm md:text-base md:h-5;
  }
  .tail-item-money {
    @apply flex justify-between;
  }
}
.tail-course-list {
  @apply p-4 md:p-0;
}

@media screen and (min-width: 768px) {
  // pc
  .item-title {
    -webkit-line-clamp: 1 !important;
  }
  .course-img-box {
    width: 100%;
    .have {
      border-radius: 0px 6px 0px 6px !important;
      font-size: 16px !important;
      padding: 4px 7px !important;
    }
  }
  .chapter {
    font-size: 16px !important;
  }
  .money {
    font-size: 16px !important;
  }
  .pagination {
    display: block !important;
  }
  .back-top {
    display: none;
  }
}
</style>
