import axios from 'axios'
// import config from './config'
import { getToken } from '@/utils/auth'
import { encrypt } from '@/utils/jsencrypt'
import router from '../router'
import Vue from 'vue'
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60 * 1000
})

// 添加一个请求拦截器
request.interceptors.request.use(
  config => {
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken('token') // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers['userType'] = 'app'
      config.headers['requestTime'] = encrypt((new Date().getTime() * 2).toString())
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// 添加一个响应拦截器
request.interceptors.response.use(
  response => {
    if (response.data.code === 0 || response.data.code === 200) {
      return response.data
    } else {
      if (response.data.code === 401) {
        // let url = window.location.href;
        // if (url.indexOf('/loginStore') == -1) {
        //   Vue.prototype.$confirm('当前登录状态已失效，是否要重新登录', '提示', {
        //     confirmButtonText: '重新登录',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   })
        //     .then(() => {
        //       router.push('/loginStore')
        //     })
        //     .catch(() => {
        //       router.go(0)

        //     }).finally(()=>{
        //       router.go(0)
        //     })
        // }
        // token 问题跳转到登录页面  没有token 跳转到登录页面  解决账号被顶掉的问题
        // router.push('/loginStore')
        // Vue.prototype.$msgTip
        //   .showTip({
        //     content: '您的账号已过期请重新登录',
        //     visibleCancle: false
        //   })
        //   .$on('onConfirm', function (data) {
        //     // location.href = getPublishBaseUrl() + '/store/#/loginStore'
        //     // location.href = 'https://test.huizhiyunping.com' + '/store/#/loginStore'
        //   })


        return Promise.reject(response.data)
      } else {
        return Promise.reject(response.data)
      }
    }
  },
  err => {
    // window.localStorage.removeItem("token");
    return Promise.reject(err)
  }
)

export default request
